function WalletSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      // marginRight="7px"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        d="M7.99967 5.16675H0.333008C0.0596745 5.16675 -0.166992 4.94008 -0.166992 4.66675C-0.166992 4.39341 0.0596745 4.16675 0.333008 4.16675H7.99967C8.27301 4.16675 8.49967 4.39341 8.49967 4.66675C8.49967 4.94008 8.27301 5.16675 7.99967 5.16675Z"
        fill="white"
      />
      <path
        d="M4.33333 10.5H3C2.72667 10.5 2.5 10.2733 2.5 10C2.5 9.72667 2.72667 9.5 3 9.5H4.33333C4.60667 9.5 4.83333 9.72667 4.83333 10C4.83333 10.2733 4.60667 10.5 4.33333 10.5Z"
        fill="white"
      />
      <path
        d="M8.66667 10.5H6C5.72667 10.5 5.5 10.2733 5.5 10C5.5 9.72667 5.72667 9.5 6 9.5H8.66667C8.94 9.5 9.16667 9.72667 9.16667 10C9.16667 10.2733 8.94 10.5 8.66667 10.5Z"
        fill="white"
      />
      <path
        d="M10.7063 13.1666H3.29301C0.639675 13.1666 -0.166992 12.3666 -0.166992 9.73992V4.25992C-0.166992 1.63325 0.639675 0.833252 3.29301 0.833252H7.99967C8.27301 0.833252 8.49967 1.05992 8.49967 1.33325C8.49967 1.60659 8.27301 1.83325 7.99967 1.83325H3.29301C1.19967 1.83325 0.833008 2.19325 0.833008 4.25992V9.73325C0.833008 11.7999 1.19967 12.1599 3.29301 12.1599H10.6997C12.793 12.1599 13.1597 11.7999 13.1597 9.73325V6.34659C13.1597 6.07325 13.3863 5.84659 13.6597 5.84659C13.933 5.84659 14.1597 6.07325 14.1597 6.34659V9.73325C14.1663 12.3666 13.3597 13.1666 10.7063 13.1666Z"
        fill="white"
      />
      <path
        d="M11.0003 4.49996C10.8736 4.49996 10.747 4.45329 10.647 4.35329L9.64695 3.35329C9.45362 3.15996 9.45362 2.83996 9.64695 2.64663C9.84029 2.45329 10.1603 2.45329 10.3536 2.64663L11.0003 3.29329L13.3136 0.979961C13.507 0.786628 13.827 0.786628 14.0203 0.979961C14.2136 1.17329 14.2136 1.49329 14.0203 1.68663L11.3536 4.35329C11.2536 4.45329 11.127 4.49996 11.0003 4.49996Z"
        fill="white"
      />
    </svg>
  );
}

export default WalletSvg;
