import React from "react";
import styles from "./transactions.module.scss";
import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import TransactionListCard from "src/components/transactionList/TransactionListCard";

function Transactions() {
  return (
    <div className={styles.transactions}>
      <div className={styles.transactionWelCard}>
        <TransactionWelCard
          heading={"View Your Transactions"}
          subHeading={"View details of all the transactions on your wallets"}
        />
      </div>
      <div className={styles.transactionTableCard}>
        <TransactionListCard />
      </div>
    </div>
  );
}

export default Transactions;
