import React from "react";
import styles from "./transactionWelCard.module.scss";

interface transWelprop {
  heading?: string;
  subHeading?: string;
}

export default function TransactionWelCard({ heading, subHeading }: transWelprop) {
  return (
    <div className={styles.welCard}>
      <div className={styles.welL}>
        <div className={styles.welText}>
          <div className={styles.welCardTitle}>
            {heading} {/* <img src={fire}></img> */}
          </div>
          <div className={styles.welCardText}>{subHeading} </div>
        </div>
      </div>
    </div>
  );
}
