import styles from "./users.module.scss";
import UserWelCard from "src/components/userWelCard/UserWelCard";
import UserListCard from "src/components/userList/UserListCard";

function Users() {
  return (
    <div className={styles.users}>
      <div className={styles.userWelCard}>
        <UserWelCard />
      </div>
      <div className={styles.userTableCard}>
        <UserListCard />
      </div>
    </div>
  );
}

export default Users;
