import React from "react";
import styles from "./orgWelCard.module.scss";
import boxLogo from "src/assets/images/cube.png";
import fire from "src/assets/images/fire.png";

export default function OrgWelCard() {
  return (
    <div className={styles.welCard}>
      <div className={styles.welL}>
        <div className={styles.welText}>
          <div className={styles.welCardTitle}>
            Manage your Organisations <img src={fire} alt="fire logo"></img>
          </div>
          <div className={styles.welCardText}>Keep on top of your organisation access and activity</div>
        </div>
      </div>
      <div className={styles.welR}>
        <img src={boxLogo} alt="box logo"></img>
      </div>
    </div>
  );
}
