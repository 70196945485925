import React, { useState, useEffect, useMemo } from "react";
import styles from "./searchBar.module.scss";
import { debounce } from "lodash";

interface SearchBarProps {
  onSearch: (_value: string) => void;
}

function SearchBar({ onSearch }: SearchBarProps) {
  const [searchValue, setSearchValue] = useState("");

  // Debounce the search function
  const debouncedSearch = useMemo(
    () =>
      debounce((searchVal: string) => {
        onSearch(searchVal);
      }, 500),
    [onSearch]
  );

  // When the search value changes, call the debounced search function
  useEffect(() => {
    debouncedSearch(searchValue);
    return debouncedSearch.cancel;
  }, [searchValue, debouncedSearch]);

  return (
    <div className={styles.searchBox}>
      <div className={styles.searchIcon}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.39961 3.2001C4.6323 3.2001 3.19961 4.63279 3.19961 6.4001C3.19961 8.16738 4.6323 9.6001 6.39961 9.6001C8.16689 9.6001 9.59961 8.16738 9.59961 6.4001C9.59961 4.63279 8.16689 3.2001 6.39961 3.2001ZM1.59961 6.4001C1.59961 3.74913 3.74864 1.6001 6.39961 1.6001C9.05057 1.6001 11.1996 3.74913 11.1996 6.4001C11.1996 7.43676 10.871 8.39666 10.3122 9.1813L14.1653 13.0344C14.4777 13.3468 14.4777 13.8534 14.1653 14.1658C13.8529 14.4782 13.3463 14.4782 13.0339 14.1658L9.18081 10.3127C8.39617 10.8715 7.43627 11.2001 6.39961 11.2001C3.74864 11.2001 1.59961 9.05106 1.59961 6.4001Z"
            fill="#64748B"
          />
        </svg>
      </div>
      <div className={styles.searchBar}>
        <input type="text" placeholder="Search" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
      </div>
    </div>
  );
}

export default SearchBar;
