import React from "react";
import styles from "./layoutSkeleton.module.scss";
import { Skeleton } from "@mui/material";

export default function LayoutSkeleton() {
  return (
    <div className={styles.container}>
      <div className={styles.navContainer}>
        <Skeleton variant="rectangular" className={styles.nav}></Skeleton>
        <div className={styles.navInput}>
          <Skeleton variant="rectangular" className={styles.icon}></Skeleton>
          <Skeleton className={styles.iconTitle}></Skeleton>
        </div>
        <div className={styles.navInput}>
          <Skeleton variant="rectangular" className={styles.icon}></Skeleton>
          <Skeleton className={styles.iconTitle}></Skeleton>
        </div>
        <div className={styles.navInput}>
          <Skeleton variant="rectangular" className={styles.icon}></Skeleton>
          <Skeleton className={styles.iconTitle}></Skeleton>
        </div>
        <div className={styles.navInput}>
          <Skeleton variant="rectangular" className={styles.icon}></Skeleton>
          <Skeleton className={styles.iconTitle}></Skeleton>
        </div>
        <div className={styles.navInput}>
          <Skeleton variant="rectangular" className={styles.icon}></Skeleton>
          <Skeleton className={styles.iconTitle}></Skeleton>
        </div>
        <div className={styles.refer}>
          <Skeleton variant="rectangular" className={styles.icon}></Skeleton>
          <Skeleton className={styles.iconTitle}></Skeleton>
        </div>
        <Skeleton variant="rectangular" className={styles.companyIcon}></Skeleton>
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.setContainer}>
          <Skeleton className={styles.breadcrum}></Skeleton>
          <div className={styles.setR}>
            <Skeleton className={styles.search}></Skeleton>
            <Skeleton className={styles.setIcon}></Skeleton>
            <Skeleton className={styles.setIcon}></Skeleton>
          </div>
        </div>
        <div className={styles.mainBody}>
          <Skeleton variant="rectangular" className={styles.bodyIn}></Skeleton>
          <Skeleton variant="rectangular" className={styles.bodyIn}></Skeleton>
        </div>
      </div>
    </div>
  );
}
