import styles from "./organisation.module.scss";
import OrgWelCard from "src/components/orgWelCard/OrgWelCard";
import OrgListCard from "src/components/orgListCard/OrgListCard";

export default function Organisation() {
  return (
    <div className={styles.organisation}>
      <div className={styles.organisationWelCard}>
        <OrgWelCard />
      </div>
      <div className={styles.organisationTableCard}>
        <OrgListCard />
      </div>
    </div>
  );
}
