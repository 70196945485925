import styles from "./loadingModal.module.scss";
import { Box, CircularProgress, Modal } from "@mui/material";

export default function LoadingModal() {
  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles.modalShadow}
    >
      <Box className={styles.modalui}>
        <CircularProgress sx={{ color: "#0f172a" }} />
      </Box>
    </Modal>
  );
}
