import styles from "./walletTransCard.module.scss";

interface WalletTransCardProps {
  icon?: string | undefined | React.ReactNode;
  title: string | undefined;
  value: number | string;
  growthval?: number;
  growthind?: boolean | undefined | "";
  from?: string | number;
  WalletTransCardClass?: string;
  balCardClass?: string;
  cardDetailClass?: string;
  cardDetailTitleClass?: string;
  cardDetailValueClass?: string;
  perGrowthClass?: string;
  growthClass?: string;
  fromDayClass?: string;
}

function WalletTransCard({
  icon,
  title,
  value,
  growthval,
  growthind,
  from,
  WalletTransCardClass,
  balCardClass,
  cardDetailClass,
  cardDetailTitleClass,
  cardDetailValueClass,
  perGrowthClass,
  growthClass,
  fromDayClass
}: WalletTransCardProps) {
  return (
    <div className={`${styles.WalletTransCard} ${WalletTransCardClass}`}>
      <div className={`${styles.balCard} ${balCardClass}`}>
        <div className={styles.cardIcon}>
          {/* <img src={icon} alt="Icon" /> */}
          {icon}
        </div>
        <div className={`${styles.cardDetail} ${cardDetailClass}`}>
          <div className={`${styles.cardDetailTitle} ${cardDetailTitleClass}`}>{title}</div>
          <div className={`${styles.cardDetailValue} ${cardDetailValueClass}`}>{value}</div>
          <div className={`${styles.perGrowth} ${perGrowthClass}`}>
            <div
              className={`${styles.growth} ${growthClass} ${
                growthind === undefined || growthind === "" ? "" : growthind ? styles.upward : styles.downward
              }`}
            >
              {growthind === undefined || growthind === "" ? null : growthind ? (
                <span>&#x25B2;</span>
              ) : (
                <span>&#x25BC;</span>
              )}
              {growthval}
            </div>
            <div className={`${styles.fromDay} ${fromDayClass}`}>{from}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WalletTransCard;
